import React from "react";
import loadable from "@loadable/component";

const Layout = loadable(() => import("@common/Layout/Layout"));
const CookiePolicy = loadable(() => import("@components/CookiePolicy"));
const SEO = loadable(() => import("@components/Common/SEO/SEO"));

const CookiePolicyPage = ({ path }) => (
    <>
        <SEO
            title="Optimize Your Web Experience: Discover How Cookies Enhance impressit.io"
            titleTemplate="Impressit"
            description="Discover how Impressit.io uses cookies to personalize content, ads, and analyze site traffic, enhancing your digital experience"
            path={path}
        />
        <Layout path={path}>
            <CookiePolicy />
        </Layout>
    </>
);

export default CookiePolicyPage;
